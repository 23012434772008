





















































import Component, { mixins } from 'vue-class-component'
import { State } from '@/mixins/state'

import { SubscriptionApi } from '@/api'

import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import FormInput, { InputField, FormInputInstance } from '@/components/Form/Input.vue'
import FormButton from '@/components/Form/Button.vue'
import { isArray } from 'lodash'
import { User } from '@/types'

interface InputFields {
  suspendTo: InputField;
  explanation: InputField;
}

@Component({
  components: {
    Content,
    Toolbar,
    FormInput,
    FormButton
  }
})
export default class UpdateSubscriptionSuspension extends mixins(State) {
  inputFields: InputFields = {
    suspendTo: {
      name: 'suspendTo',
      value: '',
      required: true,
      type: 'date',
      placeholder: 'Graag opschorten tot',
      errors: [],
      min: '',
      max: ''
    },
    explanation: {
      name: 'explanation',
      value: '',
      required: false,
      type: 'textarea',
      placeholder: 'Toelichting',
      errors: []
    }
  }

  get currentUser (): User {
    return this.$store.state.currentUser
  }

  get subscription () {
    return this.currentUser.subscriptionDetails
  }

  // Min date is current date.
  get minFromDate () {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`
  }

  // Max date is 6 months in advance, from the suspend start date.
  get maxToDate () {
    const date = new Date(this.subscription?.suspendedFrom || '')
    date.setMonth(date.getMonth() + 6)
    date.setHours(date.getHours() - 24)
    return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`
  }

  get suspendEndDateString () {
    const date = new Date(this.subscription?.suspendedTo || '')
    return date.toLocaleDateString('nl-NL', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })
  }

  get suspendFromDateString () {
    const date = new Date(this.subscription?.suspendedFrom || '')
    return date.toLocaleDateString('nl-NL', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })
  }

  mounted () {
    this.inputFields.suspendTo.value = this.minFromDate
    this.inputFields.suspendTo.min = this.minFromDate
    this.inputFields.suspendTo.max = this.maxToDate
  }

  public async suspendSubscription () {
    this.clearErrors()
    if (!this.validate() || !this.inputFields) {
      return
    }

    const api = new SubscriptionApi()
    try {
      this.state = this.states.LOADING
      this.clearErrors()

      await api.subscriptionUpdateSubscriptionSuspension({
        subscriptionId: this.currentUser.subscription?.subscriptionId || 0,
        suspendFrom: this.subscription?.suspendedFrom || '',
        suspendTo: this.inputFields.suspendTo.value,
        explanation: this.inputFields.explanation.value
      })

      this.state = this.states.COMPLETE
    } catch (error) {
      this.state = this.states.ERROR

      this.setErrors(error.response.data.errors)
    }
  }

  public setErrors (errors: []) {
    if (this.inputFields) {
      Object.entries(errors).forEach(([errorKey, errorValue]) => {
        Object.entries(this.inputFields).forEach(([fieldKey, fieldValue]) => {
          if (errorKey.toLowerCase() === fieldKey.toLowerCase()) {
            fieldValue.errors = errorValue
          }
        })
      })
    }
  }

  public clearErrors () {
    if (this.inputFields) {
      Object.values(this.inputFields).forEach((fieldValue: InputField) => {
        fieldValue.errors = []
      })
    }
  }

  public validate () {
    let valid = true
    if (this.inputFields) {
      Object.values(this.inputFields).forEach((fieldValue: InputField) => {
        const field = (this.$refs[fieldValue.name] as FormInputInstance)
        if (isArray(field)) {
          valid = field.every((inputField: FormInputInstance) => inputField.validate()) && valid
        } else {
          valid = field.validate() && valid
        }
      })
      return valid
    }
  }
}
